* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Background-Color */

.bg-fff4de {
  background-color: #fff4de !important;
}

.bg-e2fff6 {
  background-color: #e2fff6 !important;
}

.bg-ffe2e5 {
  background-color: #ffe2e5 !important;
}

.bg-e1f0ff {
  background-color: #e1f0ff !important;
}

.bg-E9F9FF {
  background-color: #E9F9FF !important;
}

.bg-263F53 {
  background-color: #263F53 !important;
}

/* Text-Color */
.text-ffad44 {
  color: #ffad44;
}

.text-25c997 {
  color: #25c997;
}

.text-f64e60 {
  color: #f64e60;
}

.text-3699ff {
  color: #3699ff;
}

.text-263F53 {
  color: #263F53 !important;
}

.text-8A8A8A {
  color: #8A8A8A;
}

.text-27BAF0 {
  color: #27BAF0;
}

.icon-data-list p {
  line-height: 15px;
}

.page-body-wrapper {
  padding-top: 60px !important;
}

@media screen and (max-width: 991px) {
  .sidebar-icon-only .sidebar-offcanvas {
    left: 0px;
  }

  .sidebar-icon-only .sidebar.close {
    left: auto !important;
  }
}

/* Modal CSS */
.modal-body form {
  max-height: 500px;
  overflow-y: scroll;
  padding: 0px 15px;
}

@media only screen and (max-width:767px) {
  .modal-body form {
    max-height: 430px;
  }
}

.navbar-brand-wrapper,
.navbar-menu-wrapper {
  background: #f7ae56 !important;
}

/* Calendar-Component */
.calendar-component {
  background-image: url(/public/images/calenderBg.png);
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #ececec;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #cacaca;
}

.sidebar {
  ::-webkit-scrollbar {
    width: 8px !important;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px transparent;
  }
}

.table,
table {
  min-width: max-content;
}



/*================================== Dashboard Page CSS start ==================================*/
.dashboard-page {
  .counter-card {
    background-color: #eee5ff;
    background-image: url(/public/images/counterCard-bg.svg);
    background-size: cover;
    background-position: top right;
    background-repeat: no-repeat;
  }
}

/*================================== Dashboard Page CSS end ==================================*/


/*================================== Loading Component CSS ==================================*/
.loding-page {
  .loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full-screen loader */
    background-color: #fbf6f0;
    /* Optional: add a background color */
  }

  .loader {
    display: flex;
    justify-content: space-between;
    width: 80px;
  }

  .dot {
    width: 16px;
    height: 16px;
    background-color: #f65365;
    border-radius: 50%;
    animation: pulse 1.2s infinite ease-in-out;
  }

  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }

  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes pulse {

    0%,
    100% {
      transform: scale(0.8);
      opacity: 0.8;
    }

    50% {
      transform: scale(1.5);
      opacity: 1;
    }
  }
}

/*================================== Loading Component CSS ==================================*/


/*================================== Chat-PAGE-Start CSS ==================================*/
.chat-page {
  .chat-list {
    height: 32rem;
  }

  .chat-list .chat-person-active {
    border-left: 2px solid #27BAF0;
  }

  .chatt {
    height: 33rem;
  }
}

/*================================== Chat-PAGE-End CSS ==================================*/